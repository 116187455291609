<template>
  <keep-alive>
    <!-- <CarConfigurator /> -->
    <router-view></router-view>
  </keep-alive>
</template>

<script>
// import CarConfigurator from './components/CarConfigurator.vue';

export default {
  name: 'App',
  // components: {
  //   CarConfigurator
  // }
}
</script>
