// router.js
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  
  {
    path: '/showroom',
    component: () => import('./pages/showroom.vue'),
  },
  
  {
    path: '/thank-you',
    component: () => import('./pages/thankyou.vue'),
  },

  {
    path: '*',
    component: () => import('./components/CarConfigurator.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  routes: [...routes],
});


export default router;
