import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import ar from './lang/ar.json'; // Import translation files for each language
import en from './lang/en.json'; // Import translation files for each language
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)
Vue.use(VueI18n);

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom-ar.css";
import "./assets/css/custom-en.css";
import "./assets/css/share.css";

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false
const i18n = new VueI18n({
  locale: 'en', // Set the default locale
  messages: {
    en: en,
    ar: ar
  }
});

// import VueThreeSixty from 'vue-360';
import VueThreeSixty from './components/I360Viewer.vue'
import 'vue-360/dist/css/style.css';

// Vue.use(VueThreeSixty);
Vue.component("vue-three-sixty", VueThreeSixty)

import VuePannellum from 'vue-pannellum';
Vue.component('VPannellum', VuePannellum)


new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
